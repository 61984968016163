<template>
	<div class="pc-bloco">
		<Aviso
			:exibir="alerta.exibir"
			:mensagem="alerta.mensagem"
			:tipo="alerta.tipo"
		/>
		<div class="pc-titleBloco">
			<h3>Formulário de Atendimento</h3>
		</div>
		<LoadingComponent :isLoading="isLoading" />
		<ValidationObserver v-slot="{ handleSubmit }">
			<form
				@submit.prevent="handleSubmit(enviarArquivos)"
				class="fileuploader center-align center-block"
			>
				<div class="row">
					<div class="col-lg-6 col-12">
						<div class="form-group">
							<SelectInput
								label="nome"
								titulo="Empreendimento"
								:reduce="(option) => option.idempreendimento"
								:options="empreendimentos"
								:valor.sync="formulario.idempreendimento"
								:placeholder="
									empreendimentos.length > 0
										? 'Selecione um Empreendimento'
										: 'Nenhum Empreendimento Ativo no Sistema'
								"
								@update:valor="escolheuEmpreendimento"
								:regras="
									empreendimentoObg || acessoSindico
										? 'required'
										: ''
								"
							/>
						</div>
					</div>
					<div class="col-lg-6 col-12" v-if="acessoSindico">
						<div class="form-group">
							<SelectInput
								label="nome"
								titulo="Área Comum"
								:reduce="(option) => option.idarea"
								:options="areas"
								:valor.sync="formulario.idarea"
								:placeholder="
									areas.length > 0
										? 'Selecione uma área comum'
										: 'Selecione o empreendimento antes de escolher a área comum '
								"
								regras="required"
							/>
						</div>
					</div>
					<div
						class="col-lg-6 col-12"
						v-if="clienteTemUnidades && !acessoSindico"
					>
						<div class="form-group">
							<SelectInput
								titulo="Unidade(s)"
								:options="unidades"
								:reduce="(option) => option.idunidade"
								:valor.sync="formulario.unidades"
								:placeholder="placeHolderUnidade"
								@update:valor="escolheuUnidade"
								:regras="unidadeObg ? 'required' : ''"
								multiple
							/>
						</div>
					</div>
					<div class="col-lg-6 col-12">
						<div class="form-group">
							<SelectInput
								label="nome"
								titulo="Assunto"
								ref="assunto"
								:options="assuntos"
								:clearable="false"
								:valor.sync="assuntoEscolhido"
								:placeholder="
									assuntos.length > 0
										? 'Selecione um Assunto'
										: 'Nenhum Assunto Ativo no Sistema'
								"
								regras="required"
								@update:valor="escolheuAssunto"
							/>
						</div>
					</div>
					<div class="col-lg-6 col-12">
						<div class="form-group">
							<SelectInput
								:label="'nome'"
								:titulo="'Subassunto'"
								:options="subassuntos"
								:clearable="false"
								:valor="subassuntoEscolhido"
								:placeholder="placeHolderSubassunto"
								@update:valor="escolheuSubassunto"
								regras="required"
							/>
						</div>
					</div>
					<div class="col-lg-6 col-12">
						<div class="form-group">
							<label for="telefone">Meu Telefone</label>
							<vue-tel-input
								id="telefone"
								v-bind="bindProps"
								v-model="formulario.telefone_atendimento"
								name="telefone_atendimento"
							>
							</vue-tel-input>
						</div>
					</div>
				</div>
				<div
					class="row"
					v-if="
						configuracoes.exibir_menu.confirmar_endereco &&
						!acessoSindico
					"
				>
					<div
						class="box-endereco"
						v-if="!formularioEnderecoAtendimento"
					>
						<DadosEndereco v-if="!formularioEnderecoAtendimento" />
						<a
							href="javascript:void(0)"
							v-if="!formularioEnderecoAtendimento"
							class="btn-form pc-btnBox pc-btnBox-secondary"
							v-on:click="formularioEnderecoAtendimento = true"
						>
							Alteração Cadastral
						</a>
					</div>
				</div>
				<FormularioEnderecoAtendimento
					class="endereco mt-10 form-endereco"
					ref="formEndereco"
					v-if="formularioEnderecoAtendimento"
					formStatus=""
				/>
				<div class="row">
					<div class="col-12">
						<div class="control-group with-top-padding">
							<input
								id="uniqid"
								type="hidden"
								name="uniqid"
								:value="formulario.uniqid"
							/>
							<label
								class="control-label"
								v-if="arquivoObrigatorio"
								><strong>*Arquivo(s):</strong></label
							>
							<label class="control-label" v-else
								>Arquivo(s):</label
							>
							<label class="error" v-if="erroSemArquivo"
								><span>Campo obrigatório</span></label
							>
							<Dropzone
								ref="dropzone"
								@sucesso="criarAtendimento"
								@erroArquivos="erroEnviarArquivos"
								:uniqid="formulario.uniqid"
							/>
						</div>
					</div>
				</div>
				<div class="row mt-20">
					<div class="col-12">
						<div class="control-group">
							<ValidationProvider
								rules="required"
								v-slot="{ errors }"
							>
								<label class="control-label" for="descricao"
									><strong>*Descrição</strong></label
								>
								<VueEditor
									v-model="formulario.descricao"
									:placeholder="placeHolderText"
									:editorToolbar="customToolbar"
								/>
								<label class="error"
									><span>{{ errors[0] }}</span></label
								>
							</ValidationProvider>
						</div>
					</div>
				</div>
				<br />
				<div class="row">
					<div class="col-12 btnEnviar g-10">
						<button
							:disabled="acessoComo"
							name="btSend"
							type="submit"
							value="Enviar"
							class="btn-form pc-btnBox pc-btnBox-secondary"
						>
							Solicitar Atendimento
						</button>
						<router-link
							:to="{ name: 'atendimentos' }"
							class="btn-form pc-btnGhost"
						>
							<span>Cancelar</span>
						</router-link>
					</div>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
import {
	getAssuntos,
	getSubassuntos,
} from "@/services/AssuntoSubassuntoService";
import { getUnidades } from "@/services/UnidadeService";
import { gerarUniqId } from "@/plugins/Utils";
import {
	cadastrarAtendimento,
	retornarAreaComum,
} from "@/services/AtendimentosService";
import Dropzone from "@/components/Dropzone";
import SelectInput from "@/components/SelectInput";
import Aviso from "@/components/Aviso";
import LoadingComponent from "@/components/LoadingComponent";
import FormularioEnderecoAtendimento from "@/pages/configuracoes/components/FormularioEnderecoAtendimento";
import DadosEndereco from "@/pages/configuracoes/components/DadosEndereco";
import { VueEditor } from "vue2-editor";
import {
	retornarEmpreendimentos,
	retornarEmpreendimentosPessoa,
} from "@/services/EmpreendimentoService";
import {
	putPessoaEndereco,
	retornarDadosPessoa,
	putPessoaAtendimento,
} from "@/services/PessoasService";
import { retornarDadosSindico } from "@/services/SindicosService";
import { mapState, mapGetters } from "vuex";
import { objetoTraking } from "../../../../plugins/Utils";
import { removerMascaraTelefone } from "../../../../plugins/Utils";
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput);

export default {
	props: {
		formularioEnderecoAtendimento: { type: Boolean, default: false },
	},
	computed: {
		...mapState({
			unidadesCliente: (state) => state.unidades.unidades,
			configuracoes: (state) => state.configuracoes.configuracoes,
		}),
		...mapGetters({
			verificarPermissao: "verificarPermissao",
			acessoComo: "getAcessoComo",
			acessoSindico: "getAcessoSindico",
		}),
	},

	created() {
		this.$mixpanel.track(
			Vue.prototype.$eventoMixPanel,
			objetoTraking(
				"Meus Atendimentos",
				"Novo Atendimento",
				"Formulário",
				true,
				false
			)
		);
	},

	components: {
		Dropzone,
		SelectInput,
		LoadingComponent,
		VueEditor,
		FormularioEnderecoAtendimento,
		DadosEndereco,
		Aviso,
		VueTelInput,
	},

	data() {
		return {
			tipoPessoa: [],
			arquivoObrigatorio: false,
			erroSemArquivo: false,
			assuntos: [],
			subassuntos: [],
			empreendimentos: [],
			areas: [],
			unidades: [],
			assuntoEscolhido: null,
			subassuntoEscolhido: null,
			isLoading: false,
			empreendimentoObg: false,
			unidadeObg: false,
			atualizarEndereco: false,
			atualizarDadosCliente: false,
			clienteTemUnidades: false,
			situacao: [],
			placeHolderSubassunto:
				"Selecione o 'Assunto' antes de escolher um Subassunto",
			placeHolderUnidade:
				"Selecione o 'Empreendimento' antes de escolher uma Unidade",
			placeHolderText:
				"Descreva com riqueza de detalhe a sua solicitação. Caso seja um problema estrutural," +
				" conte quando percebeu o problema e o que está acontecendo",
			alerta: {
				exibir: false,
				mensagem: null,
				tipo: null,
			},
			formulario: {
				idassunto: null,
				idsubassunto: null,
				idempreendimento: null,
				idunidade: null,
				titulo: null,
				telefone_atendimento: null,
				uniqid: null,
				descricao: null,
				idarea: null,
			},
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4, 5, 6] }],
				["bold", "italic", "underline", "strike"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" },
				],
				["blockquote", "code-block"],
				[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
				[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
				["clean"], // remove formatting button
			],

			bindProps: {
				nationalMode: false,
				separateDialCode: true,
				formatOnDisplay: true,
				initialCountry: "BR",
				preferredCountries: ["BR"],
				autoHideDialCode: true,
				enabledCountryCode: true,
				mode: "international",
				validCharactersOnly: true,
				inputOptions: {
					showDialCode: false,
				},
			},
		};
	},

	beforeMount() {
		this.formulario.uniqid = gerarUniqId();
		this.clienteTemUnidades =
			this.unidadesCliente && this.unidadesCliente.length > 0;
		if (this.acessoSindico) {
			retornarDadosSindico()
				.then((result) => {
					this.empreendimentos =
						result.data.empreendimentos_associados;
					if (this.empreendimentos.length == 1) {
						this.formulario.idempreendimento =
							this.empreendimentos[0].idempreendimento;
						this.escolheuEmpreendimento(
							this.formulario.idempreendimento
						);
					} else {
						this.formulario.idempreendimento = null;
					}
					getAssuntos()
						.then((response) => {
							this.assuntos = response.data;
						})
						.catch((error) => {});
				})
				.catch((error) => {});
		} else {
			retornarDadosPessoa()
				.then((result) => {
					this.tipoPessoa = result.data.tipo.nome;
					if (this.tipoPessoa != "Cliente") {
						getAssuntos().then((response) => {
							this.assuntos = response.data;
						});
					}
				})
				.catch((error) => {});

			if (this.clienteTemUnidades) {
				retornarEmpreendimentosPessoa("criar_atendimentos")
					.then((response) => {
						this.empreendimentos =
							this.verificarEmpreendimentosPermissao(
								response.data
							);

						if (this.empreendimentos.length === 0) {
							for (let empreendimento of response.data) {
								if (empreendimento.permitir_criar_atendimento) {
									this.empreendimentos.push(empreendimento);
								}
							}
						}

						if (this.empreendimentos.length == 1) {
							this.formulario.idempreendimento =
								this.empreendimentos[0].idempreendimento;
							this.escolheuEmpreendimento(
								this.formulario.idempreendimento
							);
						} else {
							this.formulario.idempreendimento = null;
						}
					})
					.catch((error) => {});
			} else {
				retornarEmpreendimentos()
					.then((response) => {
						this.empreendimentos =
							this.empreendimentosAtivosAtendimentos(
								response.data
							);

						if (this.empreendimentos.length == 1) {
							this.formulario.idempreendimento =
								this.empreendimentos[0].idempreendimento;
							this.escolheuEmpreendimento(
								this.formulario.idempreendimento
							);
						} else {
							this.formulario.idempreendimento = null;
						}
					})
					.catch((error) => {});
			}
		}
	},

	methods: {
		verificarEmpreendimentosPermissao(empreendimentos) {
			let emprPermitidos = [];
			for (let empreendimento of empreendimentos) {
				if (!empreendimento.permitir_criar_atendimento) {
					continue;
				}

				for (let perfil of empreendimento.perfis) {
					if (perfil.length === 0) {
						break;
					}
					if (
						this.verificarPermissao(
							perfil.sigla,
							"criar_atendimentos"
						)
					) {
						emprPermitidos.push(empreendimento);
						break;
					}
				}
			}
			return emprPermitidos;
		},

		escolheuAssunto(assunto) {
			if (assunto) {
				this.formulario.idassunto = assunto.idassunto;
				this.arquivoObrigatorio = assunto.arquivos_obrigatorios;
				this.setarSubassunto(assunto.idassunto);
			}
		},

		escolheuUnidade(unidade) {
			let posicao = this.unidades.findIndex(
				(element) => element.idunidade === unidade[unidade.length - 1]
			);
			let dadosUnidade = this.unidades[posicao];
			if (dadosUnidade) {
				if (dadosUnidade.grupo_especial != null) {
					alert(
						`Essa unidade faz parte de um grupo especial (${dadosUnidade.label})`
					);
				}
			}
		},

		setarSubassunto(idAssunto) {
			getSubassuntos(idAssunto, this.formulario.idempreendimento)
				.then((response) => {
					this.subassuntos = response.data;
					if (this.subassuntos.length == 1) {
						this.subassuntoEscolhido = this.subassuntos[0];
						this.formulario.idsubassunto =
							this.subassuntos[0].idsubassunto;
					} else {
						this.subassuntoEscolhido = null;
						this.formulario.idsubassunto = null;
					}
					this.placeHolderSubassunto = "Selecione um Subassunto";
				})
				.catch((error) => {})
				.finally(() => {
					this.escolheuSubassunto(this.subassuntoEscolhido);
				});
		},

		escolheuSubassunto(subassunto) {
			if (subassunto) {
				this.subassuntoEscolhido = subassunto;
				this.formulario.idsubassunto = subassunto.idsubassunto;
				this.empreendimentoObg = subassunto.empreendimento_obrigatorio;
				this.unidadeObg = subassunto.unidade_obrigatoria;
				this.arquivoObrigatorio = subassunto.arquivos_obrigatorios
					? subassunto.arquivos_obrigatorios
					: this.arquivoObrigatorio;
			} else {
				this.empreendimentoObg = false;
			}
		},

		escolheuEmpreendimento(idEmpreendimento) {
			this.formulario.unidades = null;
			this.assuntoEscolhido = null;
			this.subassuntoEscolhido = null;
			this.assuntos = [];
			this.subassuntos = [];
			getAssuntos(idEmpreendimento)
				.then((response) => {
					this.assuntos = response.data;
				})
				.catch((error) => {});
			if (this.acessoSindico && idEmpreendimento) {
				this.retornarAreasComunsEmpreendimentos(idEmpreendimento);
			} else {
				if (this.clienteTemUnidades && idEmpreendimento) {
					getUnidades(idEmpreendimento, "criar_atendimentos")
						.then((response) => {
							var unidades = [];
							for (let elemento of response.data) {
								var unidade = {
									label: `${elemento.bloco.nome} - ${elemento.nome}`,
									idunidade: elemento.idunidade,
									grupo_especial: elemento.grupo_especial,
								};
								unidades.push(unidade);
							}
							this.unidades = unidades;
							if (this.unidades.length == 1) {
								this.formulario.unidades = [
									this.unidades[0].idunidade,
								];
							}
							this.placeHolderUnidade =
								"Selecione a(s) Unidade(s)";
						})
						.catch((error) => {});
				} else {
					this.unidades = [];
				}
			}
		},

		retornarAreasComunsEmpreendimentos(idEmpreendimento) {
			retornarAreaComum(idEmpreendimento)
				.then((response) => {
					this.areas = response.data.areas;
					if (this.areas.length == 1) {
						this.formulario.idarea = this.areas[0].idarea;
					}
				})
				.catch((error) => {});
		},

		enviarArquivos() {
			if (this.acessoComo) {
				return;
			}
			console.log(
				JSON.stringify({
					data: this.formulario.uniqid,
					origem: "upload_arquivos_atendimentos",
				})
			);
			this.isLoading = true;
			if (this.$refs.formEndereco) {
				this.atualizarEndereco =
					this.$refs.formEndereco.verificarForm();
			}

			this.erroSemArquivo = false;
			if (
				this.arquivoObrigatorio &&
				this.$refs.dropzone.quantidadeArquivos() === 0
			) {
				this.erroSemArquivo = true;
				this.isLoading = false;
				return;
			}

			this.$refs.dropzone.enviarArquivos();
		},

		async criarAtendimento() {
			this.trakingMixpanel(
				"Novo Atendimento",
				"Criar Atendimento",
				true,
				true
			);
			this.formulario.telefone_atendimento = removerMascaraTelefone(
				this.formulario.telefone_atendimento
			);
			var continuarCadastrar = true;
			if (this.atualizarEndereco) {
				await putPessoaEndereco(this.atualizarEndereco)
					.then((response) => {})
					.catch((error) => {
						this.alerta = {
							exibir: true,
							mensagem:
								"Erro interno ao tentar confirmar o endereço.",
							tipo: "erro",
						};
						continuarCadastrar = false;
						this.isLoading = false;
					});
			}

			if (this.formularioEnderecoAtendimento) {
				let formCliente =
					this.$refs.formEndereco.verificarFormDadosCliente();

				if (formCliente) {
					await putPessoaAtendimento(formCliente)
						.then((response) => {})
						.catch((error) => {
							this.alerta = {
								exibir: true,
								mensagem:
									"Erro interno ao tentar confirmar os dados do Cliente.",
								tipo: "erro",
							};
							this.isLoading = false;
						});
				}
			}

			if (continuarCadastrar) {
				this.formulario.titulo =
					this.$refs.assunto.$el.innerText.split("\n")[1];
				cadastrarAtendimento(this.formulario)
					.then((response) => {
						this.$router.push({
							name: "atendimento",
							params: { idAtendimento: response.data.id },
						});
					})
					.catch((error) => {
						this.$refs.dropzone.removerArquivos();
						this.alerta = {
							exibir: true,
							mensagem: Object.values(error.response.data)[0],
							tipo: "erro",
						};
					})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},

		empreendimentosAtivosAtendimentos(empreendimentos) {
			var empreendimentoAtendimento = [];
			for (let empreendimento of empreendimentos) {
				if (empreendimento.permitir_criar_atendimento) {
					empreendimentoAtendimento.push(empreendimento);
				}
			}
			return empreendimentoAtendimento;
		},

		erroEnviarArquivos(error) {
			this.alerta = {
				exibir: true,
				mensagem:
					"Erro interno ao tentar enviar o(s) anexo(s) do atendimento.",
				tipo: "erro",
			};
		},

		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking(
					"Meus Atendimentos",
					acao,
					subacao,
					intencao,
					ateracao
				)
			);
		},
	},
};
</script>

<style lang="css">
.endereco {
	padding-top: 10px;
	border-top: 1px dashed #d8d8d8;
	padding-bottom: 20px;
	border-bottom: 1px dashed #d8d8d8;
}

.with-top-padding {
	padding-top: 10px;
}
.btnEnviar {
	display: flex;
	flex-wrap: wrap;
	gap: 10px 15px;
}
</style>
