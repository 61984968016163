<template>
	<div class="pc-content">
		<BreadCrumbs :caminho="caminho" />
		<TituloPagina
			titulo="Abrir um novo atendimento"
			subtitulo="Painel com informações da sua unidade"
		/>

		<div class="row">
			<div class="col-12">
				<Formulario />
			</div>
		</div>
	</div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import Formulario from "./components/novoAtendimento/FormularioAtendimento";
import TituloPagina from "@/components/TituloPagina";

export default {
	name: "NovoAtendimento",

	components: {
		BreadCrumbs,
		TituloPagina,
		Formulario,
	},

	data() {
		return {
			caminho: [
				{
					texto: "Meus Atendimentos",
					rota: "atendimentos",
				},
				{
					texto: "Novo Atendimento",
					ativo: true,
				},
			],
		};
	},
};
</script>

<style>
label.error {
	position: absolute;
	right: 40px;
	bottom: 5px;
}
</style>
